
.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 550px;
    /* height: 209px; */
    /* width: 669px; */
    padding-top: 40px;
}

.loginbg {
    background: linear-gradient(to bottom,rgb(98,52,174),rgb(233,218,241));
    width: 100%;
    padding-bottom: 40px;
    min-height: 100vh;
}

.normal_input .ant-input{
    outline: none !important;
    border: 0!important;
    border-bottom: 1px solid #b1b1b1!important;
}

.container .ant-input-password{
    outline: none !important;
    border: 0!important;
    border-bottom: 1px solid #b1b1b1!important;
}

.container .ant-input-group{
    outline: none !important;
    border: 0!important;
    border-bottom: 1px solid #b1b1b1!important;
}

.loginForm{
    width: 80%;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: 0 auto;
    padding-top: 20px;
}

.loginForm .ant-form-item-explain-error{
    text-align: left;
}

#box_register .loginForm{
    width: 40%;
}

.loginForm .ant-form-item-label > label {
    /* color: rgba(0, 0, 0, 0.4); */
    font-weight: 550;
}

.reginfo .ant-form-item-label > label {
    /* color: rgba(0, 0, 0, 0.4); */
    font-weight: 500;
}

.loginForm .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
    content: '';
}

.h4, .h4{
    font-size: 20px;
}


#box_registerok {
    display: flex;
    width: 95%;
    height: 95%;
    justify-content: center;
    align-items: center;
    /* padding: 50px; */
    /* border-radius:20px; */
    /* background-color: white; */
    /* background:linear-gradient(#ff0000,#0000ff); */
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: auto;
}
