.tab_pane {
    border: solid 1px;
    border-radius: 10px;
    border-color: #ffffff;
    background-color: #ffffff;
    padding: 6px 15px;
    font-weight: 550;
    opacity: 0.6
}

.tab_pane_active {
    border: solid 1px;
    border-radius: 10px;
    border-color: #3f8cff;
    background-color: #ffffff;
    padding: 6px 15px;
    font-weight: 550;
    opacity: 0.6
}