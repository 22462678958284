.panel-heading {
    padding: 15px;
    padding-bottom: 0;
    background: #e8edf0;
    border-color: #e8edf0;
    position: relative;
}

.panel-lead {
    margin-bottom: 15px;
}

.nav-tabs {
    border-bottom: 0;
    margin-bottom: 0;
}

