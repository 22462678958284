.ant-tree-list-holder-inner{
    flex:none
    /* display: "inline-block" !important; */
}

.auth-leaf{
    float: left;
    /* color: red; */
    /* display: inline-flex; */
}

.auth-noleaf{
    width: 100% !important;
}

.ant-tree-switcher-leaf-line{
    display: none;

}
