
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  z-index: 2000;
}

.row-dragging td {
  padding: 12px 8px;
}

.row-dragging .drag-visible {
  visibility: visible;
}
